import React from "react"
import { Card, CardBody, Row , Col} from "reactstrap"

const Miniwidget = props => {
  return (
    <React.Fragment>
      <Row>
        {props.reports.map((report, key) => (
          <Col xl={4} sm={6} key={key}>
            <Card className="mini-stat bg-primary" style={{ height: '200px', padding: '20px' }}
            >
              <CardBody className="card-body mini-stat-img">
                <div className="mini-stat-icon">
                  <i className={"float-end mdi mdi-" + report.iconClass}></i>
                </div>
                <div className="text-white">
                <h4 className="text-uppercase mb-3 text-white">{report.title}</h4>
                <h1 className="mb-4" style={{ fontWeight: "bold" }}>
                    {report.total}
                  </h1>
                  {/* <span className={"badge bg-" + report.badgecolor}> {report.average} </span> <span className="ms-2">From previous period</span> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )

}

export default Miniwidget
