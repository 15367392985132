import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"


import { withTranslation } from "react-i18next"

const ShareDropdown = props => {
  const [menu, setMenu] = useState(false)

  //  WhatsApp sharing link
  const whatsappShareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    'Check out this amazing content: ' + window.location.href
  )}`

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-share-dropdown"
        >
          <i className="mdi mdi-share-variant"></i> {/* Share icon */} 
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0"> {props.t("Share")} </h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {/* WhatsApp Share */}
            <Link to="#" className="text-reset notification-item" onClick={() => window.open(whatsappShareLink, '_blank')}>
              <div className="d-flex align-items-center">
            
                <i 
                  className="mdi mdi-whatsapp me-3" 
                  style={{ fontSize: '36px', color: '#25D366' }}
                ></i>
                <div className="flex-grow-1">
                  <h6 className="mb-1">Share via WhatsApp</h6>
                </div>
              </div>
            </Link>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ShareDropdown.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(ShareDropdown)
