import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import AIReviewCreation from "components/AIReview/AIReviewCreation"
import NegativeReviewForm from "components/AIReview/NegativeReviewForm"

// Activating fake backend
fakeBackend()

const App = props => {
  const location = useLocation()

  const [params, setParams] = useState({ businessname: "" })
  const [isNegativeReviewFormRendered, setIsNegativeReviewFormRendered] =
    useState(false)

  useEffect(() => {
    const getUrlParams = () => {
      const path = location.pathname
      const parts = path.split("/") // Splits URL path by "/"
      const businessname = parts[1] // Since the structure is /JackMex/672228462e2fd9b2d06fe9da
      // const _id = parts[2]
      return { businessname }
    }

    // Update the params state when the URL changes
    setParams(getUrlParams())
  }, [location.pathname]) // This dependency array ensures it runs when the pathname changes

  const Layout = getLayout()

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  console.log("businessname", params.businessname) // Should log "JackMex"
  // console.log("parameters", params._id) // Should log "672228462e2fd9b2d06fe9da"

  // Conditional Rendering Logic
  const isNegativeReviewForm = location.pathname === "/negative-review-form"

  useEffect(() => {
    if (isNegativeReviewForm && !isNegativeReviewFormRendered) {
      setIsNegativeReviewFormRendered(true)
    }
  }, [isNegativeReviewForm, isNegativeReviewFormRendered])

  return (
    <React.Fragment>
      <Routes>
        {/* Non-authenticated routes */}
        {authRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
          />
        ))}

        {/* Authenticated routes */}
        {userRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
          />
        ))}
      </Routes>

      {/* Render NegativeReviewForm if URL matches and hasn't been rendered yet */}
      {isNegativeReviewForm && !isNegativeReviewFormRendered ? (
        <NegativeReviewForm />
      ) : !isNegativeReviewFormRendered ? ( // Render AIReviewCreation only if NegativeReviewForm has not rendered
        <AIReviewCreation businessname={params.businessname} />
      ) : null}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
