import React from "react"
import { Row, Col } from "reactstrap"
import { connect } from 'react-redux';
import {Link} from "react-router-dom"

const Breadcrumb = props => {

const itemLength = (props.breadcrumbItems || []).length || 1;

  return (
    <Row>
      <Col sm={6}>
        <div className="page-title-box">

          <h4>{props.title}</h4>
          <ol className="breadcrumb m-0">
            {
              (props.breadcrumbItems || []).map((item, key) =>
                (key + 1) === itemLength ?
                  <li key={key} className="breadcrumb-item active">{item.title}</li>
                  : <li key={key} className="breadcrumb-item"><Link to="#">{item.title}</Link></li>
              )
            }
          </ol>
        </div>
      </Col>

      <Col sm={6}>
        <div className="state-information d-none d-sm-block">
          <div className="state-graph">
            <div className="info" style={{ fontSize: '16px' }}>
            <span style={{ fontWeight: 'bold', fontSize: '20px'}}>50</span> : Negative Review
            </div>
        
          </div>
          <div className="state-graph">
           
            <div className="info" style={{ fontSize: '16px' }}>
            <span style={{ fontWeight: 'bold', fontSize: '20px'}}>125</span> : Positive Review
            </div>
            
          </div>
        </div>
      </Col>
    </Row>
  )
}

const mapStatetoProps = state => {
  const Layout = state.Layout;
  const BreadcrumbData = state.Breadcrumb
  return { layoutType: Layout.layoutType, title: BreadcrumbData.title, breadcrumbItems: BreadcrumbData.breadcrumbItems };
};

export default connect(mapStatetoProps, {})(Breadcrumb);
