import React, { useEffect, useState } from "react"
import { Card, CardBody, CardText } from "reactstrap"
import { QRCodeCanvas } from "qrcode.react"

const MonthlyEarnings = ({ merchantData }) => {
  const [logoUrl, setLogoUrl] = useState("")
  const [scannerUrl, setScannerUrl] = useState("")

  useEffect(() => {
    // Check if logo is a URL string or a buffer and handle accordingly
    if (typeof merchantData?.logo === "string") {
      setLogoUrl(merchantData.logo) // Directly use the string URL
    } else if (merchantData?.logo?.data) {
      // If it's a buffer, convert it to a Blob URL
      const convertBufferToBlobUrl = bufferData => {
        const blob = new Blob([new Uint8Array(bufferData)], {
          type: "image/jpeg",
        }) // Adjust MIME type as needed
        return URL.createObjectURL(blob)
      }

      const logoUrl = convertBufferToBlobUrl(merchantData.logo.data)

      setLogoUrl(logoUrl)
    }

    // Check if scanner is a URL string or a buffer and handle accordingly
    if (typeof merchantData?.scanner === "string") {
      setScannerUrl(merchantData.scanner) // Directly use the string URL
    } else if (merchantData?.scanner?.data) {
      // If it's a buffer, handle conversion (if needed)
      const convertBufferToUrl = bufferData => {
        // Convert buffer to a valid URL dynamically if required
        return "http://localhost:5000/JackMex" // Replace this with dynamic logic
      }
      const scannerUrl = convertBufferToUrl(merchantData.scanner.data)
      setScannerUrl(scannerUrl)
    }
  }, [merchantData])

  return (
    <React.Fragment>
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderLeft: `10px solid ${merchantData.themeColor}`,
          borderRight: `10px solid ${merchantData.themeColor}`,
        }}
      >
        <CardBody style={{ textAlign: "center" }}>
          {/* Company Logo */}
          {logoUrl ? (
            <img
              src={logoUrl}
              alt="Company Logo"
              className="mb-4"
              style={{ maxWidth: "200px", height: "auto" }}
            />
          ) : (
            <p>Logo not available</p>
          )}

          <CardText
            className="mb-2"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: merchantData.textColor,
              lineHeight: "1.1",
            }}
          >
            PLEASE TAKE A MIN AND
          </CardText>
          <CardText
            className="mb-2"
            style={{
              color: merchantData.textColor,
              fontWeight: "bold",
              fontSize: "1.5rem",
              lineHeight: "1.1",
            }}
          >
            REVIEW US
          </CardText>

          <div className="mt-4">
            {scannerUrl && (
              <>
                <QRCodeCanvas
                  value={scannerUrl}
                  size={256}
                  level="H"
                  includeMargin={true}
                />
              </>
            )}
          </div>

          {/* AI Powered Review Text */}
          <CardText
            className="mb-2"
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              lineHeight: "1.1",
              color: merchantData.textColor,
            }}
          >
            WITH OUR ALL NEW
          </CardText>
          <CardText className="mb-4">
            <span
              style={{
                backgroundColor: merchantData.themeColor,
                fontWeight: "bold",
                color: merchantData.textColor,
                padding: "0 10px",
                fontSize: "1.5rem",
                lineHeight: "1.1",
              }}
            >
              AI POWERED REVIEW
            </span>
          </CardText>

          {/* Footer text */}
          <CardText className="mb-2" style={{ fontSize: "1rem" }}>
            <small>Powered By: PostReview</small>
          </CardText>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarnings
