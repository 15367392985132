import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Logo from "../../assets/images/Logo.png"
import { resetProfileFlag } from "../../store/actions"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

const NegativeReviewForm = props => {
  document.title = "Profile | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [submitMessage, setSubmitMessage] = useState("")

  const selectProfileState = state => state.Profile
  const ProfileProperties = createSelector(selectProfileState, profile => ({
    success: profile.success,
  }))

  const { error, success } = useSelector(ProfileProperties)
  const rating = localStorage.getItem("activeStars")
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      phone: "",
      aigeneratedreview: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(/^[A-Za-z ]*$/, "Name should contain only letters")
        .required("Please Enter Your Name"),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
        .required("Please Enter Phone Number"),
      aigeneratedreview: Yup.string().required("Please Enter Review Summary"),
    }),
    onSubmit: async values => {
      const payload = {
        merchantInfoId: "613b8a8f4e3b2c7a4f0b4d6d",
        name: values.username,
        mobileNo: values.phone,
        status: "Pending",
        rating: rating || "No Rating Provided",
        aigeneratedreview: values.aigeneratedreview,
        resolutionComment: "The issue has been escalated to the team.",
        resolvedBy: "64fa5c0b65f4a933df9bcf34",
      }

      try {
        const response = await fetch(
          "http://88.222.213.199:5001/negativeReviews",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          },
        )

        if (response.ok) {
          setSubmitMessage("Review submitted successfully!")
          validation.resetForm()
        } else {
          setSubmitMessage("Failed to submit review. Please try again.")
        }
      } catch (error) {
        console.error("Error submitting review:", error)
        setSubmitMessage("An error occurred. Please try again.")
      }
    },
  })
  console.log("Active Stars:", props.activeStars)
  return (
    <React.Fragment>
      <Row
        className="justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Col xl="8" lg="8" md="10" sm="12" xs="12">
          <Col>
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}
            {submitMessage && (
              <Alert
                color={
                  submitMessage.includes("successfully") ? "success" : "danger"
                }
              >
                {submitMessage}
              </Alert>
            )}
          </Col>

          <Card>
            <CardBody>
              <div className="text-center mb-4">
                <img
                  src={Logo}
                  alt="Merchant Logo"
                  className="img-fluid"
                  style={{ maxWidth: "150px" }}
                />
              </div>
              <h4
                className="card-title mb-4 text-left"
                style={{ padding: "0px 10px" }}
              >
                We're Sorry to Hear That
              </h4>
              <p style={{ padding: "0px 10px" }}>
                Please let us know what went wrong, and we'll do our best to fix
                it.
              </p>
              <Form
                style={{ padding: "0px 10px" }}
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <div className="form-group mt-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter Your Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username}
                    invalid={
                      validation.touched.username && validation.errors.username
                    }
                  />
                  {validation.touched.username &&
                    validation.errors.username && (
                      <FormFeedback>{validation.errors.username}</FormFeedback>
                    )}
                </div>

                <div className="form-group mt-3">
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    name="phone"
                    className="form-control"
                    placeholder="Enter Your Phone Number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                    }
                  />
                  {validation.touched.phone && validation.errors.phone && (
                    <FormFeedback>{validation.errors.phone}</FormFeedback>
                  )}
                </div>

                <div className="form-group mt-3">
                  <Label className="form-label">
                    AI-generated Review Summary
                  </Label>
                  <Input
                    style={{ height: "100px" }}
                    name="aigeneratedreview"
                    className="form-control"
                    placeholder="Enter Your Review"
                    type="textarea"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.aigeneratedreview}
                    invalid={
                      validation.touched.aigeneratedreview &&
                      validation.errors.aigeneratedreview
                    }
                  />
                  {validation.touched.aigeneratedreview &&
                    validation.errors.aigeneratedreview && (
                      <FormFeedback>
                        {validation.errors.aigeneratedreview}
                      </FormFeedback>
                    )}
                </div>

                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={!validation.isValid || validation.isSubmitting}
                  >
                    Submit Review
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(NegativeReviewForm)
