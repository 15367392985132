import React, { useState } from "react";
import { Formik, Form } from 'formik';
import { Button, Label, Input, FormFeedback, Row, Col, Alert } from 'reactstrap';
import * as Yup from 'yup';
import axios from 'axios';

// Validation schema using Yup
const validationSchema = Yup.object({
  question1: Yup.string().required('Question 1 is required'),
  keyword1: Yup.array().min(1, 'At least one keyword is required'), 
  question2: Yup.string().required('Question 2 is required'),
  keyword2: Yup.array().min(1, 'At least one keyword is required'),
});

const AIForm = () => {
  const [submitMessage, setSubmitMessage] = useState("");
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axios.post("http://88.222.213.199:5001/questionForms", {
       
        question1: values.question1,
        question1Keywords: values.keyword1,   
        question2: values.question2,
        question2Keywords: values.keyword2,   
      });
  
      
        setSubmitMessage("Data submitted successfully!");
        console.log('Form data:', response.data);
        resetForm();
      
    } catch (error) {
      setSubmitMessage("Failed to submit data.");
    }
  };
  

  return (
    <Formik
      initialValues={{
        question1: '',
        keyword1: [],
        question2: '',
        keyword2: [],
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, isSubmitting }) => (
        <Form style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px' }}>
          <Row>
            <Col md={8}>
              {submitMessage && (
                <Alert color={submitMessage === "Data submitted successfully!" ? "success" : "danger"}>
                  {submitMessage}
                </Alert>
              )}
              {/* Question 1 */}
              <div className="mb-3">
                <Label htmlFor="question1">Enter Your First Question.</Label>
                <Input
                  type="text"
                  name="question1"
                  id="question1"
                  placeholder="Enter your first question"
                  value={values.question1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.question1 && !!errors.question1}
                />
                {touched.question1 && errors.question1 && (
                  <FormFeedback>{errors.question1}</FormFeedback>
                )}
              </div>

              {/* Keywords for Question 1 */}
              <div className="mb-3">
                <Label htmlFor="keyword1">Keywords for First Question.</Label>
                <small className="text-muted d-block mb-1">Separate each keyword using a comma or press Enter</small>
                <div className="token-input-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {Array.isArray(values.keyword1) && values.keyword1.map((keyword, index) => (
                    <div key={index} className="token" style={{ backgroundColor: '#C7C8CC', padding: '4px 8px', borderRadius: '4px', margin: '2px', display: 'inline-flex', alignItems: 'center' }}>
                      {keyword}
                      <span
                        className="token-remove"
                        style={{ marginLeft: '8px', cursor: 'pointer', color: 'black' }}
                        onClick={() => setFieldValue('keyword1', values.keyword1.filter(k => k !== keyword))}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                  <Input
                    type="text"
                    placeholder="Enter keywords"
                    onKeyDown={(e) => {
                      const newKeyword = e.target.value.trim();
                      if (e.key === 'Enter' || e.key === ',') {
                        e.preventDefault();
                        if (newKeyword && !values.keyword1.includes(newKeyword)) {
                          setFieldValue('keyword1', [...values.keyword1, newKeyword]);
                          e.target.value = '';
                        }
                      }
                    }}
                    onBlur={(e) => {
                      const newKeyword = e.target.value.trim();
                      if (newKeyword && !values.keyword1.includes(newKeyword)) {
                        setFieldValue('keyword1', [...values.keyword1, newKeyword]);
                        e.target.value = '';
                      }
                      setFieldTouched('keyword1', true);
                      handleBlur(e);
                    }}
                    className={`token-input-field ${touched.keyword1 && errors.keyword1 ? 'is-invalid' : ''}`}
                  />
                </div>
                {touched.keyword1 && errors.keyword1 && (
                  <FormFeedback style={{ display: 'block' }}>{errors.keyword1}</FormFeedback>
                )}
              </div>

              {/* Question 2 */}
              <div className="mb-3">
                <Label htmlFor="question2">Enter Your Second Question.</Label>
                <Input
                  type="text"
                  name="question2"
                  id="question2"
                  placeholder="Enter your second question"
                  value={values.question2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.question2 && !!errors.question2}
                />
                {touched.question2 && errors.question2 && (
                  <FormFeedback>{errors.question2}</FormFeedback>
                )}
              </div>

              {/* Keywords for Question 2 */}
              <div className="mb-3">
                <Label htmlFor="keyword2">Keywords for Second Question.</Label>
                <small className="text-muted d-block mb-1">Separate each keyword using a comma or press Enter</small>
                <div className="token-input-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {Array.isArray(values.keyword2) && values.keyword2.map((keyword, index) => (
                    <div key={index} className="token" style={{ backgroundColor: '#C7C8CC', padding: '4px 8px', borderRadius: '4px', margin: '2px', display: 'inline-flex', alignItems: 'center' }}>
                      {keyword}
                      <span
                        className="token-remove"
                        style={{ marginLeft: '8px', cursor: 'pointer', color: 'black' }}
                        onClick={() => setFieldValue('keyword2', values.keyword2.filter(k => k !== keyword))}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                  <Input
                    type="text"
                    placeholder="Enter keywords"
                    onKeyDown={(e) => {
                      const newKeyword = e.target.value.trim();
                      if (e.key === 'Enter' || e.key === ',') {
                        e.preventDefault();
                        if (newKeyword && !values.keyword2.includes(newKeyword)) {
                          setFieldValue('keyword2', [...values.keyword2, newKeyword]);
                          e.target.value = '';
                        }
                      }
                    }}
                    onBlur={(e) => {
                      const newKeyword = e.target.value.trim();
                      if (newKeyword && !values.keyword2.includes(newKeyword)) {
                        setFieldValue('keyword2', [...values.keyword2, newKeyword]);
                        e.target.value = '';
                      }
                      setFieldTouched('keyword2', true);
                      handleBlur(e);
                    }}
                    className={`token-input-field ${touched.keyword2 && errors.keyword2 ? 'is-invalid' : ''}`}
                  />
                </div>
                {touched.keyword2 && errors.keyword2 && (
                  <FormFeedback style={{ display: 'block' }}>{errors.keyword2}</FormFeedback>
                )}
              </div>
            </Col>
          </Row>
          {/* Submit Button */}
          <Button color="primary" type="submit" className="mt-3" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AIForm;
