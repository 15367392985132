import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"

// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import axios from "axios"

const Dashboard = ({ setBreadcrumbItems }) => {
  document.title = "Dashboard | Lexa - Responsive Bootstrap 5 Admin Dashboard"

  const { businessname } = useParams() // Extracting `businessname` from the URL
  const [merchantData, setMerchantData] = useState(null) // State to store merchant data
  const [loading, setLoading] = useState(true) // Loading state
  const [error, setError] = useState(null) // Error state

  useEffect(() => {
    setBreadcrumbItems("Dashboard")

    // Fetch data from the API
    const fetchMerchantData = async () => {
      try {
        if (!businessname) {
          setError("Businessname is required")
          return
        }

        const response = await axios.get(
          `http://88.222.213.199:5001/merchantsinfos/${businessname}`,
        )

        if (response && response.data) {
          setMerchantData(response.data) // Set fetched data to state
        } else {
          setError("No data found")
        }
      } catch (error) {
        console.error("Error fetching merchant data:", error)
        setError("Failed to fetch merchant data")
      } finally {
        setLoading(false)
      }
    }

    fetchMerchantData()
  }, [businessname, setBreadcrumbItems])

  const reports = [
    {
      title: "Positive Reviews",
      iconClass: "thumb-up",
      total: merchantData?.positiveReviews || "0",
      average: "+15%",
      badgecolor: "success",
    },
    {
      title: "Negative Reviews",
      iconClass: "thumb-down",
      total: merchantData?.negativeReviews || "0",
      average: "-5%",
      badgecolor: "danger",
    },
    {
      title: "Total QR Code Scans",
      iconClass: "qrcode-scan",
      total: merchantData?.totalQrScans || "0",
      average: "+20%",
      badgecolor: "primary",
    },
  ]

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <React.Fragment>
      {/* Mini widgets */}
      <Miniwidget reports={reports} />

      <Row>
        <Col xl="4">
          {/* Monthly Earnings */}
          <MonthlyEarnings merchantData={merchantData} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

// Adding PropTypes to enforce the businessname prop type

export default connect(null, { setBreadcrumbItems })(Dashboard)
